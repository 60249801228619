import request from '@manage/utils/request'

export function handleScorerloginpagemanageSave(data) { //保存记分长登陆首页
  return request({
    url: 'admin/scorerloginpagemanage/save',
    method: 'post',
    data
  })
}



export function handleScorerloginpagemanageDetails(id) { //记分长登陆首页详情
  return request({
    url: 'admin/scorerloginpagemanage/details',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handleScorerloginpagemanagePageQuery(data) { //分页查询记分长登陆首页
  return request({
    url: 'admin/scorerloginpagemanage/pageQuery',
    method: 'post',
    data
  })
}



export function handleScorerloginpagemanageStateControl(id) { //启用停用控制
  return request({
    url: 'admin/scorerloginpagemanage/stateControl',
    method: 'post',
    params:{id:id}
  })
}
