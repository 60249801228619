import {
	Message
} from 'element-ui'

import {
	handleScorerloginpagemanageSave,
	handleScorerloginpagemanageDetails,
	handleScorerloginpagemanagePageQuery,
	handleScorerloginpagemanageStateControl
} from '@manage/api/bm/scorerLoginIndex';
export default {
	name: 'scorerLoginIndex',
	components: {},
	data() {
		return {
			queryForm: {},
			tableData: [],
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			tableRowClassName:'',
			form: {
				pcLogo: '',
				appletLogo: ''
			},
			dialogVisible: false,
			headers: {
				'Authorization': sessionStorage.getItem('token')
			},
			path: process.env.VUE_APP_BASE_API + "sys/sysResource/uploadImgs",
			uploadFileParams: {
				type: 'img',
				path: 'scorer-logo',
				userDefineType: 'logo'
			},
			rules: {
				schemeName: [{
					required: true,
					message: '请输入方案名称',
					trigger: 'blur'
				}],
				subDomain: [{
					required: true,
					message: '请输入域名',
					trigger: 'blur'
				}],
				webTitle: [{
					required: true,
					message: '请输入网站标题',
					trigger: 'blur'
				}],
				leader: [{
					required: true,
					message: '请输入联系人',
					trigger: 'blur'
				}],
				leaderPhone: [{
					required: true,
					message: '请输入联系电话',
					trigger: 'blur'
				}],
				pcLogo: [{
					required: true,
					message: 'PC-LOGO必须上传',
					trigger: 'blur'
				}]
			},
		}
	},
	watch: {

	},
	created() {
		this.fastQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handleScorerloginpagemanagePageQuery({
				pageSize: this.pageSize,
				currPage: this.currPage,
				leader: this.queryForm.leader,
				schemeName: this.queryForm.schemeName,
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		submitInfo() { //保存
			if (this.form.subDomain) {
				this.form.domain = "https://" + this.form.subDomain + ".dance5.top";
			}
			handleScorerloginpagemanageSave(this.form).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.dialogVisible = false;
					this.fastQuery();
					this.form = {
						pcLogo: '',
						appletLogo: ''
					};
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			});
		},
		uploadSuccessPC(res) {
			if (res.status == 200) {
				Message({
					message: res.msg,
					type: 'success',
					duration: 5 * 1000
				});
				this.form.pcLogo = res.data[0].url;
			} else {
				Message({
					message: res.msg,
					type: 'error',
					duration: 5 * 1000
				});
			}

		},
		uploadSuccessApplet(res) {
			if (res.status == 200) {
				Message({
					message: res.msg,
					type: 'success',
					duration: 5 * 1000
				});
				this.form.appletLogo = res.data[0].url;
			} else {
				Message({
					message: res.msg,
					type: 'error',
					duration: 5 * 1000
				});
			}
		},
		openAddDialog() { //打开新增对话
			this.form = {
				pcLogo: '',
				appletLogo: ''
			};
			this.dialogVisible = true;
		},
		stateControl(id) { //切换状态
			handleScorerloginpagemanageStateControl(id).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			});
		},
		openModifyDialog(id) { //打开修改对话框
			this.dialogVisible = true;
			handleScorerloginpagemanageDetails(id).then(res => {
				if (res.status == 200) {
					this.form = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					});
				}
			})
		},
		resetForm() {
			this.queryForm = {};
			this.fastQuery();
		},
	}
}